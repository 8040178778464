<template>
  <mobile-screen
    :header="true"
    screen-class="resources-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "resources",
              "resource-capacity-type-time-types",
              "resource-capacity-type-time-types"
            )
          }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <template
      v-if="
        resourceCapacityTypeDates &&
          resourceCapacityTypeDates.data &&
          resourceCapacityTypeDates.data.length
      "
    >
      <resource-capacity-type-time-form
        v-for="item in resourceCapacityTypeDates.data"
        :key="item.id"
        :id="item.id"
        :preselectedData="item"
        :delete-item-fn="deleteItem"
      ></resource-capacity-type-time-form>
    </template>
    <resource-capacity-type-time-form
      v-for="(item, idx) in fieldNum"
      :key="item"
      :id="idx"
      :initial="true"
      :delete-item-fn="deleteItem"
    ></resource-capacity-type-time-form>

    <ul class="clebex-item-section">
      <li class="clebex-item-section-item">
        <button class="clebex-item-content-wrapper" @click="addNewItem">
          <span class="label">{{
            displayLabelName(
              "resources",
              "resource-capacity-type-time-types",
              "add-new-item"
            )
          }}</span>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <!-- TODO fali ova ikonica -->
              <svg-icon icon="add"></svg-icon>
            </span>
          </span>
        </button>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";

export default {
  name: "ResourceCapacityTypeTime",
  data() {
    return {
      fieldNum: [1]
    };
  },
  mounted() {
    const { resource } = this;

    if (
      resource &&
      resource.data &&
      this.canItem(this.resource.data, "update")
    ) {
      this.getResourceCapacityTypeDateData(resource.data.id);
    } else {
      this.$router.push({ name: "r_resources" });
    }
  },
  computed: {
    ...mapState("resource", ["resource", "resourceCapacityTypeDates"])
  },
  methods: {
    ...mapActions("resource", ["getResourceCapacityTypeDateData"]),
    addNewItem() {
      this.fieldNum.push(this.fieldNum.length);
    },
    deleteItem(index) {
      this.fieldNum.splice(index, 1);
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    ResourceCapacityTypeTimeForm: defineAsyncComponent(() =>
      import("@/components/resources/ResourceCapacityTypeTimeForm")
    )
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
